<template>
  <div class="head-menu-area">
    <img src="../../../assets/pc/logo.png" />
    <div class="menu">
      <a href="#sample">{{TITLE_SAMPLES}}</a>
      <a href="#operation">{{TITLE_RECOGNITION}}</a>
      <a href="#description">{{TITLE_INTRODUCTION}}</a>
    </div>
  </div>
</template>
<script>
import HomeMixin from '../../../mixins/home-mixins'

export default {
  name: 'PcFooter',
  mixins: [HomeMixin],
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
  .head-menu-area{
    width: 1200px;
    color: #ffffff;
    font-size: 18px;
    margin: 0 auto;
      display: flex;
      justify-content: space-between;
      >img{
        width: 151px;
        height: 19px;
        display: inline-block;
        margin-top: 24px;
      }
      >div{
        font-weight: 400;
        display: inline-block;
        line-height: 25px;
        margin-top: 19px;
        a {
          margin-left: 50px;
          color: white;
        }
      }
  }
</style>
