import { createRouter, createWebHistory } from 'vue-router'
import { checkPcDevice } from '../utils'
import PcRoutes from './router-pc'
import MobileRoutes from './router-mobile'

const routes = [
  ...PcRoutes,
  ...MobileRoutes
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
