import EnvUtil from '../utils/env-util'

const apiHost = EnvUtil.baseUrl().emulianBaseUrl
// const apiHost = 'https://rests.mirror.emulian.com'

/** 图像上传 */
const naddUploadUrl = '/web/p/nadd/upload.service'

export default {
  uplaodByForm(res, file, resolve, reject) {
    const xhr = new XMLHttpRequest()

    xhr.open('POST', apiHost + naddUploadUrl, true)
    // xhr.setRequestHeader('Content-Type', 'multipart/form-data')

    const formData = new FormData()
    // formData.append('v', 0)
    const userId = Math.floor((Math.random() * 10))
    formData.append('mbtoken', 'emulian')
    formData.append('hashsign', 'emulian_sign')
    formData.append('mid', 0)
    formData.append('from', 'album')
    formData.append('isremote', 0)
    formData.append('uid', userId)
    formData.append('username', `form_designer_random_uid_${userId}`)
    formData.append('upalbum', file)

    xhr.send(formData)
    xhr.onprogress = (xhrres) => {
      console.log('progress', xhrres)
    }
    xhr.onreadystatechange = () => {
      // console.log(xhr)
      if (xhr.readyState === 4) {
        const resData = JSON.parse(xhr.response)
        // console.log(resData)
        resolve(resData, file)
      } else {
        reject(xhr)
      }
    }
  }
}
