import PcLogin from '../components/pc/Login.vue'
import PcHome from '../components/pc/PcHome.vue'

const routes = [
  {
    path: '/PcLogin',
    name: 'PcLogin',
    component: PcLogin
  }, {
    path: '/PcHome',
    name: 'PcHome',
    component: PcHome
  }
]
export default routes
