<template>
  <div class="head-area">
    <!-- menu -->
    <a-layout-header :class="isScroll?'fixed-hedad':''"
                     :style="{ position: 'fixed', zIndex: 1000, width: '100%', left: 0 }">
      <pc-header-menu></pc-header-menu>
    </a-layout-header>
    <!-- content -->
    <div class="content">
      <p class="title">象牙样本</p>
      <div class="details">
        <p>
          大象是地球上珍贵的野生动物资源，
          非洲象，亚洲象均被替换为《濒临灭绝的野生动植物种国际贸易公约》
          （CITES ）目录，象牙及其制品的市场贸易持续得到国家决策层和学术界的从抵抗制象牙及制品做起。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import PcHeaderMenu from './pcHeader/PcHeaderMenu.vue'

export default {
  name: 'PcHeader',
  data() {
    return {
      isScroll: false
    }
  },
  components: {
    PcHeaderMenu
  },
  created() {
    window.addEventListener('scroll', () => {
      if ((document.body.scrollTop || document.documentElement.scrollTop) > 0) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    })
  }
}
</script>

<style lang="less" scoped>
  .head-area{
    width: 100%;
    min-height: 350px;
    background: #6c6f5c;
    .fixed-hedad{
      height: 60px;
      background-color: #6c6f5c;
      box-shadow: 0px 7px 7px -7px #5E5E5E;
    }
    .content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding-top: 50px;
      .title {
        height: 59px;
        font-size: 42px;
        font-weight: 600;
        color: #ffffff;
        line-height: 59px;
      }
      .details {
        width: 100%;
        p {
          width: 534px;
          min-height: 96px;
          padding-bottom: 53px;
          margin: 0 auto;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 32px;
        }
      }
    }
  }
</style>
