import { get } from '../utils/http'
import EnvUtil from '../utils/env-util'
import Service from '../utils/service-api'

const baseUrl = process.env.VUE_APP_NODE_ENV == 'prod' ? EnvUtil.baseUrl().emulianBaseUrl : '/rests'
const apiHost = process.env.VUE_APP_NODE_ENV == 'prod' ? EnvUtil.baseUrl().magpieBaseUrl : '/api'
const uploadHost = EnvUtil.baseUrl().uploadBaseUrl
// const apiHost = 'https://rests.mirror.emulian.com'

/** 获取首页木材标本示例图片 */
const exampleImgsUrl = '/web/g/exampleimgs/magpie.service'
// const exampleImgsJsonFileUrl = 'https://fe.resource.emulian.com/images/ivory/img.examples.json'
const exampleImgsMetaJsonFileUrl = 'https://fe.resource.emulian.com/images/ivory/img-meta.with-link.examples.json'
/** 图像分类 */
const classifyUrl = '/web/g/classify/owl.service'

export default {
  loadExampleImgs(resolve, reject) {
    return get(apiHost, Service.url().magpie.examples, '', resolve, reject).catch(function(reason) {
      console.log('catch:', reason)
    })
  },
  loadExampleImgsByFile(resolve, reject) {
    return get('', exampleImgsMetaJsonFileUrl, '', resolve, reject).catch(function(reason) {
      console.log('catch:', reason)
    })
  },
  classifyImage(params, resolve, reject) {
    return get(apiHost, Service.url().magpie.classify + '?' + params, {}, resolve, reject).catch(function(reason) {
      console.log('catch:', reason)
    })
  },
  onlineModelInfo(params, resolve, reject) {
    return get(apiHost, Service.url().magpie.onlineModelInfo, params, resolve, reject).catch(function(reason) {
      console.log('catch:', reason)
    })
  }
}
