<template>
  <div class="footer-area">
    <div class="footer-main">
      <div class="content">
        <img src="../../assets/pc/logo.png" />
        <div class="menu">
          <a href="#sample">{{TITLE_SAMPLES}}</a>
          <span>|</span>
          <a href="#operation">{{TITLE_RECOGNITION}}</a>
          <span>|</span>
          <a href="#description">{{TITLE_INTRODUCTION}}</a>
        </div>
        <div class="phone">
          Powered by <a href="https://timbercld.com" target="_blank">timbercld.com</a>
        </div>
      </div>
      <div class="contact">
        <p class="title">联系我们</p>
        <p>南京森林警察学院</p>
        <p>联系电话：025-85878800</p>
        <p>单位地址：南京仙林大学城文澜路28号</p>
      </div>
    </div>
  </div>
</template>

<script>
import HomeMixin from '../../mixins/home-mixins'

export default {
  name: 'PcFooter',
  mixins: [HomeMixin]
}
</script>

<style lang="less" scoped>
  .footer-area {
    background-color: #6C6F5C;
    width: 100%;
    // height: 214px;
    padding-bottom: 1.56rem /* 20/12.8 */;
    font-size: 18px;
    color: #ffffff;
    .footer-main {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 auto;
      text-align: left;
      padding-top: 44px;
      .content {
        flex: 1;
        >img {
          width: 252px;
          height: 31px;
        }
        .menu {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          margin: 36px 0 12px;
          >span{
            margin-left: 20px;
            margin-right: 20px;
          }
          >a{
            color: #ffffff;
            cursor: pointer;
          }
        }
        .phone {
          font-weight: 400;
          line-height: 25px;
          a {
            color: #fff;
            text-decoration: underline;
            &:hover {
              color: #ddbf83;
            }
          }
        }
      }
      .contact {
        flex: 1;
        p {
          font-size: 16px;
          margin-bottom: 5px;
          &.title {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
