<template>
  <div>
    <swiper class="swiper-list" @slideChange="onSlideChange">
      <swiper-slide v-for="(slides, index) in caroulselInfo" :key="index">
        <div class="swiper-item">
          <swiper :slides-per-view="5" navigation :centered-slides="true" :loop="slides.length >= 5" :ref="'swiperItem'+index">
            <swiper-slide v-for="(item, i) in slides" :key="i">
              <!-- {{slides.virtualIndex + '  ss ' + i}} -->
              <img :src="item" />
            </swiper-slide>
          </swiper>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, Virtual } from 'swiper'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Virtual])
export default {
  computed: {
    caroulselInfo() {
      const result = []
      const it = this.menuIfo.length
      // 分类的循环
      for (let i = 0; i < it; i++) {
        const it1 = this.menuIfo[i].examples.length
        const result1 = []
        for (let i1 = 0; i1 < it1; i1++) {
          result1.push(this.menuIfo[i].examples[i1])
        }
        result.push(result1)
        result[i].virtualIndex = 2
      }
      return result
    }
  },
  data() {
    return {
      swiperList: ''
    }
  },
  mounted () {
    this.swiperList = new SwiperCore('.swiper-list', {
      virtual: false,
      initialSlide: 0,
      noSwiping: true
    })
  },
  methods: {
    onSlideChange(e) {
      this.$emit('slideIndex', e.snapIndex)
    },
    onSlideItemChange(e, index) {
      console.log(e.snapIndex)
      this.caroulselInfo[index].virtualIndex = e.snapIndex
    }
  },
  watch: {
    'active'(n, o) {
      if (n !== o) {
        this.swiperList.slideTo(n, 300, false)
        this.swiperList.initialSlide = n
        this.swiperList.init()
      }
    }
  },
  props: ['menuIfo', 'active'],
  components: {
    Swiper,
    SwiperSlide
  }
}
</script>
<style lang="less" scoped>
  /* For demo */
  .swiper-list {
    .swiper-item {
      /deep/.swiper-container {
        .swiper-button-next, .swiper-button-prev {
          background: rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          color: #fff;
          width: 60px;
          height: 60px;
          opacity: 0;
          transition: all .3s linear;
          &::after {
            font-size: 30px;
          }
        }
        &:hover {
          .swiper-button-next, .swiper-button-prev {
            opacity: 1;
          }
        }
        .swiper-slide {
          text-align: center;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          transition: 300ms;
          transform: scale(0.73);
          width: 330px;
          img {
            border: 4px solid #DDBF83;
            border-radius: 50%;
            width: 330px;
            height: 330px;
          }
          &.swiper-slide-prev {
            z-index: 1;
            margin-left: -20px;
            margin-right: 20px;
          }
          &.swiper-slide-next {
            z-index: 1;
            margin-right: -20px;
            margin-left: 20px;
          }
        }
        .swiper-slide-active,.swiper-slide-duplicate-active{
          transform: scale(1);
          z-index: 2;
        }
      }
    }
  }
</style>
