import axios from 'axios'
// import Cookies from 'js-cookie'
const JSONBig = require('json-bigint')({ storeAsString: true }) // JSON数据中，长整型Long转换为字符串

const request = axios.create({
  withCredentials: false
})

request.interceptors.request.use(
  (config) => {
    // 长整型Long转换为字符串
    // eslint-disable-next-line no-param-reassign
    config.transformResponse = [function (data) {
      return JSONBig.parse(data)
    }]
    // eslint-disable-next-line no-param-reassign
    // config.headers.token = Cookies.get('token') || ''
    // 防止缓存，GET请求默认带_t参数
    // 登录成功后，GET请求默认带token参数
    if (config.method === 'get') {
      // eslint-disable-next-line no-param-reassign
      config.params = {
        ...config.params,
        ...{ _t: new Date().getTime() }
      }
    }
    return config
  },
  (error) => {
    // console.log('error', error)
    return Promise.reject(new Error(error).message)
  }
)

request.interceptors.response.use(
  (response) => {
    // console.log('INFO: ', response)
    return response
  },
  (error) => {
    // console.log('error', error)
    return Promise.reject(new Error(error).message)
  }
)

export default request
