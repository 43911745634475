import MobileHome from '../components/mobile/MobileHome.vue'
import ResultPage from '../components/mobile/ResultPage.vue'
// //eslint-disable-next-line import/extensions
import MobileLogin from '../components/mobile/Login'

const routes = [
  {
    path: '/MobileLogin',
    name: 'MobileLogin',
    component: MobileLogin
  },
  {
    path: '/MobileHome',
    name: 'MobileHome',
    component: MobileHome
  },
  {
    path: '/ResultPage',
    name: 'ResultPage',
    component: ResultPage
  }
]
export default routes
