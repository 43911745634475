import apiFn from '../apis/magpie'
import upload from '../apis/upload'
import { checkPcDevice } from '../utils'
import { message } from 'ant-design-vue'
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
  data() {
    return {
      /** Constants */
      TITLE: '象牙智能识别系统',
      TITLE_RECOGNITION: '象牙识别',
      TITLE_SAMPLES: '象牙样本',
      TITLE_INTRODUCTION: '项目介绍',
      TEXT_INTRODUCTION: '大象是地球上珍贵的野生动物资源，非洲象，亚洲象均被替换为《濒临灭绝的野生动植物种国际贸易公约》（CITES ）目录，象牙及其制品的市场贸易持续得到国家决策层和学术界的从抵抗制象牙及制品做起，对保护野生动物资源具有重要意义，而在执法过程中如何快速，有效的对类象牙制品进行鉴定与识别，是目前亟待解决的问题。 本项目采用图像增强与纹理解析方法，来辅助解决象牙图像纹理的量化解析问题，通过深度学习算法实现象牙图像鉴定和识别',
      NAME_CATEGORY_ELEPHANT: '现生象牙',
      NAME_CATEGORY_MAMMOTH: '猛犸象牙',
      NAME_CATEGORY_SUBSTITUTES: '象牙仿制品',
      examples: [],
      classifyModel: [],
      uploading: false
    }
  },
  created () {
    if (!Cookies.get('ID')) {
      this.getOnlineModelInfo()
    }
  },
  methods: {
    getOnlineModelInfo() {
      apiFn.onlineModelInfo({token: Cookies.get('token')}, function(res) {
        if (res.erc !== 0) {
          return message.error(res.msg)
        }
        Cookies.set('ID', res.data[0].identification)
      }, function(err) {
        return message.error(res.msg)
      })
    },
    getExamplesList() {
      let _this = this
      apiFn.loadExampleImgs(function(res) {
        if (res.erc !== 0) {
          return message.error(res.msg)
        }
        _this.examples = res.data
      }, function(err) {
        return message.error(err.msg)
      })
    },
    classifyFile(params) {
      let _this = this
      apiFn.classifyImage(params, function(res) {
        // console.log(res)

        if (res.erc !== 0) {
          return message.error(res.msg)
        }
        _this.classifyModel = res.data
        _this.uploading = false
      }, function(err) {
        return message.error(err.msg)
      })
    },
    getFile(e) {
      let _this = this
      _this.uploading = true
      let file = e.target.files[0]
      let param = new FormData() //创建form对象
      param.append('file',file,file.name) //通过append向form对象添加数据
      param.append("id",Cookies.get('token'))
      console.log(file)
      let config = {
        headers: {
          'Content-Type':'multipart/form-data'
        }
      }  //添加请求头
      upload.uplaodByForm(param, file, function(res) {
        console.log(res)
        if (!checkPcDevice()) {
          this.uploading = false
          this.$router.push({path: '/ResultPage', query: { img: res.data.fileurlArray[0] }})
        } else {
          let params = qs.stringify({
            model: Cookies.get('ID'),
            img: res.data.fileurlArray[0]
          })
          this.classifyFile(params)
        }
      }.bind(this), function(err) {
        console.log(err)
      })
    },
    // 点击触发input的点击事件
    clickFile(){
      this.$refs.leftFile.click()
    }
  }
}
