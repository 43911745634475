import { createApp } from 'vue'
import {
  Button,
  Card,
  Carousel,
  Menu,
  Upload,
  Modal,
  Spin,
  Input,
  Image,
  message
} from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less'

const components = [
  Button,
  Card,
  Carousel,
  Menu,
  Upload,
  Modal,
  Spin,
  Input,
  Image,
  message
]

const app = createApp(App)
components.forEach((component) => {
  app.use(component)
})
app.use(store).use(router)
  .mount('#app')
// createApp(App)
//   .use(store)
//   .use(router)
//   .use(Button)
//   .use(Card)
//   .use(Carousel)
//   .use(Menu)
//   .mount('#app')
