/** utils.js */
export function getFileExtension(fileName) {
  const strTmps = fileName.split('.')
  let fileExtension = ''
  if (strTmps && strTmps.length > 1) {
    fileExtension = strTmps[strTmps.length - 1]
  }
  return fileExtension
}

/**
 * 权限
 * @param {*} key
 */
export function hasPermission(key) {
  // return window.SITE_CONFIG['permissions'].indexOf(key) !== -1 || false
}

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(
      16
    )
  })
}

/**
 * 获取svg图标(id)列表
 */
export function getIconList() {
  const res = []
  const list = document.querySelectorAll('svg symbol')
  for (let i = 0; i < list.length; i++) {
    res.push(list[i].id)
  }

  return res
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'pid') {
  const res = []
  const temp = {}
  for (let i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (let k = 0; k < data.length; k++) {
    if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
      res.push(data[k])
      // eslint-disable-next-line no-continue
      continue
    }
    if (!temp[data[k][pid]].children) {
      temp[data[k][pid]].children = []
    }
    temp[data[k][pid]].children.push(data[k])
    data[k]._level = (temp[data[k][pid]]._level || 0) + 1
  }
  return res
}

/**/
export function mergeString(a, b) {
  return `${a},${b}`
}

export function imgUpload(res, file, fileList, origin) {
  if (!fileList[origin]) {
    // eslint-disable-next-line no-param-reassign
    fileList[origin] = []
  }
  fileList[origin].push({ url: res.data.fileurlArray[0] })
  console.log(fileList, origin, fileList[origin])
}
/** 数组转对象
 * @param {Array} 数据源
 * @param {any} key
 * @param {any} value-key
 */
export function arrToObj(data, id = 'id', name = 'name') {
  const obj = {}
  data.map((v) => {
    obj[v[id]] = v[name]
    return obj[v[id]]
  })
  return obj
}

// 时间戳方案
export function throttle(fn, wait) {
  let pre = Date.now()
  return function () {
    const context = this
    // eslint-disable-next-line prefer-rest-params
    const args = arguments
    const now = Date.now()
    if (now - pre >= wait) {
      fn.apply(context, args)
      pre = Date.now()
    }
  }
}

// 使用递归的方式实现数组、对象的深拷贝
export function deepClone(obj) {
  // 判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
  const objClone = Array.isArray(obj) ? [] : {}
  // 进行深拷贝的不能为空，并且是对象或者是
  if (obj && typeof obj === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}

export function checkPcDevice() {
  const userAgentInfo = navigator.userAgent
  console.log(userAgentInfo)
  const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  for (let i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > 0) {
      return false
    }
  }
  return true
}
