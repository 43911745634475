<template>
  <div id="main">
    <div>
      <router-view/>
    </div>
<!--    <div class="powered-by">-->
<!--      <div class="powered-by-text">-->
<!--        <span title="技术支持">Powered by </span>-->
<!--        <a href="https://www.emulian.com/RD-Center/" target="_blank" title="技术支持:木链云研发中心">timbercld.com</a>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { checkPcDevice } from './utils'
import Cookies from 'js-cookie'
import { NavigationFailureType, isNavigationFailure } from 'vue-router'
export default {
  name: 'App',
  created() {
    this.checkDeviceAndAdjust()
  },
  mounted () {
    let _this = this
    window.addEventListener('resize', () => {
      _this.checkDeviceAndAdjust()
    })
  },
  methods: {
    // 检测设备类型并适配
    checkDeviceAndAdjust() {
      const isPc = checkPcDevice()
      this.$store.commit('setDevice', isPc)
      this.adjustDevice(isPc)
    },
    adjustDevice(isPc) {
      let path = ''
      if (isPc) {
        // if (!this.$route.path.startsWith('/Pc')) {
          path = Cookies.get('token') ? '/PcHome' : '/PcLogin'
          // this.$router.push('/Pc/Login')
        // }
      // } else if (!this.$route.path.startsWith('/Mobile')) {
      } else {
        path = Cookies.get('token') ? '/MobileHome' : '/MobileLogin'
        // this.$router.push('/Mobile/Login')
      }
      if (this.$route.path === 'ResultPage') return
      this.$router.push(path).then(failure => {
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
          failure.to.path // '/admin'
          failure.from.path // '/'
        }
      })
    }
  }
}
</script>

<style lang="less">
#main {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.powered-by {
  /*position: absolute;*/
  /*bottom: 0;*/
  margin-top: 10px;
  width: 100%;
  .powered-by-text {
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
  }
}
</style>
