<template>
  <div class="result-page">
    <div class="main" v-if="classifyModel.length > 0">
      <p class="title">{{classifyModel[0].meta ? classifyModel[0].meta.meta[0].value : '未知产品'}}</p>
      <div class="example">
        <div class="img-box">
          <div class="kuang kuang__1"></div>
          <div class="kuang kuang__2"></div>
          <div class="kuang kuang__3"></div>
          <div class="kuang kuang__4"></div>
          <div class="kuang kuang__5"></div>
          <div class="kuang kuang__6"></div>
          <div class="kuang kuang__7"></div>
          <div class="kuang kuang__8"></div>
          <!-- <img :src="$route.query.img" /> -->
          <a-image
            width="100%"
            :src="$route.query.img"
          />
        </div>
      </div>
      <div class="detail">
        <p class="title">详细信息</p>
        <p class="item">类别：{{classifyModel[0].meta ? classifyModel[0].meta.meta[0].value : '未知类别'}}</p>
        <p class="item">置信度：{{classifyModel[0].meta ? classifyModel[0].confidence_percent : '0%'}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import HomeMixin from '../../mixins/home-mixins'
import qs from 'qs'
import Cookies from 'js-cookie'
export default {
  mixins: [HomeMixin],
  data() {
    return {
    }
  },
  mounted () {
    let params = qs.stringify({
      img: this.$route.query.img,
      model: Cookies.get('ID')
    })
    this.classifyFile(params)
  }
}
</script>
<style lang="less" scoped>
.result-page {
  box-sizing: border-box;
  padding-top: 5.67vw;
  .main {
    box-sizing: border-box;
    margin: 0 4vw;
    background: #DDBF83;
    padding: 5.67vw;
    .title {
      font-size: 4.27vw;
      color: #333;
      font-weight: bold;
    }
    .example {
      margin: 5.67vw auto;
      width: 68.8vw;
      // height: 48vw;
      box-sizing: border-box;
      padding: 2.67vw 3.73vw;
      position: relative;
      .kuang {
        position: absolute;
        background: #666;
        &__1 {
          top: 0;
          left: 0;
          height: 3px;
          width: 12px;
        }
        &__2 {
          top: 0;
          right: 0;
          height: 3px;
          width: 12px;
        }
        &__3 {
          top: 0;
          right: 0;
          height: 12px;
          width: 3px;
        }
        &__4 {
          bottom: 0;
          right: 0;
          height: 12px;
          width: 3px;
        }
        &__5 {
          bottom: 0;
          right: 0;
          height: 3px;
          width: 12px;
        }
        &__6 {
          bottom: 0;
          left: 0;
          height: 3px;
          width: 12px;
        }
        &__7 {
          bottom: 0;
          left: 0;
          height: 12px;
          width: 3px;
        }
        &__8 {
          top: 0;
          left: 0;
          height: 12px;
          width: 3px;
        }
      }
      .img-box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
    .detail {
      font-size: 3.2vw;
      color: #333;
      text-align: left;
      .title {
        font-weight: bold;
      }
      .item {
        margin-top: 2.67vw;
        &:last-child {
          margin-top: 1.33vw;
        }
      }
    }
  }
}
</style>
