/** http.js */
// 导入封装好的axios实例
import qs from 'qs'
import request from './request'

export function get(apiHost, url, params, resolve, reject) {
  let strParams = ''
  if (params) {
    strParams = qs.stringify(params)
  }
  return request.get(apiHost + url + (strParams ? `?${strParams}` : '')).then(({ data: res }) => {
    if (res) {
      resolve(res)
    } else {
      reject()
    }
  })
}

export default request
