export default {
  /**
   * 服务端 API 请求路径，分为三个环境，每个环境的路径可能不同
   *
   * dev - 本地开发环境，本地启动后台服务
   * mirror - 测试联调环境，
   * prod - 生产环境
   *
   * */
  url(context) {
    const urls = {
      dev: {
        magpie: {
          examples: '/web/g/ivoryList/example.service',
          classify: '/web/g/classify/deepmodel.service',
          onlineModelInfo: '/web/g/lst/deepmodel.service'
        }
      },
      mirror: {
        magpie: {
          examples: '/web/g/ivoryList/example.service',
          classify: '/web/g/classify/deepmodel.service',
          onlineModelInfo: '/web/g/lst/deepmodel.service'
        }
      },
      prod: {
        magpie: {
          examples: '/web/g/ivoryList/example.service',
          classify: '/web/g/classify/deepmodel.service',
          onlineModelInfo: '/web/g/lst/deepmodel.service'
        }
      }
    }
    if (context) {
      return urls[context.env.VUE_APP_NODE_ENV]
    }
    return urls[process.env.VUE_APP_NODE_ENV]
  }
}
