<template>
  <div class="clearfix">
    <a-upload
      :action="imgHost + '/web/p/nadd/upload.service'"
      :disabled="(title === '查看') || (status === 'A')"
      list-type="picture-card"
      :file-list="fileList"
      :before-upload="getBefore"
      @preview="handlePreview"
      @change="handleChange"
      @click="test"
    >
      <div v-if="fileList.length < 1">
        <PlusOutlined></PlusOutlined>
        <div class="ant-upload-text">
          <p>请上传10M以内清晰的象牙横切面纹理图像</p>
          <p>点击上传</p>
        </div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-spin class="uploadMask" tip="Loading..." v-if="uploading"></a-spin>
  </div>
</template>
<script>
import { PlusOutlined } from '@ant-design/icons-vue'
import qs from 'qs'
import Cookies from 'js-cookie'
import EnvUtil from '../../../utils/env-util'
import upload from '../../../apis/upload'
import apiFn from '../../../apis/magpie'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  data() {
    return {
      imgHost: process.env.VUE_APP_NODE_ENV == 'pro' ? EnvUtil.baseUrl().emulianBaseUrl : '/rests',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      fileFormData: {},
      uploading: false
    }
  },
  components: {
    PlusOutlined
  },
  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      // console.log(file)
      if (!file.url && !file.preview) {
        // eslint-disable-next-line no-param-reassign
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBefore(file) {
      let _this = this
      this.uploading = true
      let param = new FormData() //创建form对象
      param.append('file',file,file.name) //通过append向form对象添加数据
      param.append("id",Cookies.get('token'))
      upload.uplaodByForm(param, file, function(res) {
        console.log(res)
        let params = qs.stringify({
          model: Cookies.get('ID'),
          img: res.data.fileurlArray[0]
        })
        _this.classifyFile(params)
      }, function(err) {
        console.log(err)
      })
      return false
    },
    handleChange(e) {
      this.fileList = e.fileList
      if (!e.fileList.length) {
        this.$emit('uploadChange', [{noUpload: true}])
      }
    },
    test(e) {
      // console.log(e)
    },
    classifyFile(params) {
      let _this = this
      apiFn.classifyImage(params, function(res) {
        // console.log(res)
        if (res.erc !== 0) {
          return message.error(res.msg)
        }
        _this.uploading = false
        _this.$emit('uploadChange', res.data)
      }, function(err) {
        return message.error(err.msg)
      })
    }
  }
}
</script>
<style>
  /* you can make up upload button and sample style by using stylesheets */
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    top: 0;
    left: 0;
  }
  .uploadMask {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 111;
    background: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    padding-top: 150px!important;
  }
</style>
