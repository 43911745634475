export default {
  baseUrl() {
    let emulianBaseUrl = ''
    let magpieBaseUrl = ''
    let uploadBaseUrl = ''
    if (process.env.NODE_ENV === 'development') {
      emulianBaseUrl = 'https://rests.emulian.com'
      magpieBaseUrl = 'https://ivory.rests.mirror.emulian.com'
      uploadBaseUrl = 'https://ivory.rests.mirror.emulian.com'
    } else if (process.env.NODE_ENV === 'mirror') {
      emulianBaseUrl = 'https://rests.mirror.emulian.com'
      magpieBaseUrl = 'https://ivory.rests.mirror.emulian.com'
      uploadBaseUrl = 'https://ivory.rests.mirror.emulian.com'
    } else if (process.env.NODE_ENV === 'production') {
      // baseUrl = 'https://rests.iwood.ai'
      emulianBaseUrl = 'https://rests.mirror.emulian.com'
      magpieBaseUrl = 'https://ivory.rests.mirror.emulian.com'
      uploadBaseUrl = 'https://ivory.rests.mirror.emulian.com'
    }
    return {
      emulianBaseUrl,
      magpieBaseUrl,
      uploadBaseUrl
    }
  }
}
