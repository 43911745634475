<template>
  <div class="main">
    <!-- head -->
    <pc-header></pc-header>
    <!-- bg -->
    <img class="bg-img" src="../../assets/pc/BG.png" />
    <!-- content -->
    <div class="content">
      <!-- sample -->
      <div class="sample" id="sample" v-if="examples.length > 0">
        <p class="title">象牙样本</p>
        <hr />
        <div class="menu">
          <div v-for="item in examples"
               :key="item.meta.index"
               :class="item.index == active? 'active' : ''"
               @click="changeClass(item.index)">
            {{item.meta[0].value}}
          </div>
        </div>
        <div class="display">
          <!-- <ImgCarousel :menuIfo="menuIfo"
                       :active="active"
                       ref="classImgs"></ImgCarousel> -->
          <ImgSwiper :menuIfo="examples"
                       :active="active"
                       @slideIndex="getSlideIndex"
                       ref="classImgs"></ImgSwiper>
        </div>
      </div>
      <!-- operation -->
      <div style="height: 100px;" id="operation"></div>
      <div class="operation">
        <div class="img-upload">
          <p>象牙识别</p>
          <img-upload class="upload-area" @uploadChange="getResult"></img-upload>
        </div>
        <!-- <div class="texture-result">
          <p>纹理提取结果</p>
          <div v-for="index in 6" :key="index"></div>
        </div> -->
        <div class="recog-result">
          <p>象牙识别结果</p>
          <div>
            <div v-if="resultData[0] && resultData[0].noUpload"></div>
            <div v-else>
              <span>类别：{{resultData[0].meta ? resultData[0].meta.meta[0].value : '未知类别'}}</span>
              <span>置信度：{{resultData[0].meta ? resultData[0].confidence_percent : '0%'}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- operation -->
      <div class="description" id="description">
        <div>
          <p>项目介绍</p>
          <hr />
          <div v-for="(item, index) in description" :key="index">{{item}}</div>
        </div>
        <img src="../../assets/pc/description.png" />
      </div>
    </div>
    <!-- foot -->
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from './PcHeader.vue'
import PcFooter from './PcFooter.vue'
// //eslint-disable-next-line import/extensions
import ImgUpload from './pcContent/ImgUpload'
import ImgSwiper from './pcContent/ImgSwiper'
// //eslint-disable-next-line import/extensions
// import ImgCarousel from './pcContent/ImgCarousel'
// //eslint-disable-next-line import/extensions,import/no-unresolved
import HomeMixin from '../../mixins/home-mixins'

export default {
  name: 'PcHome',
  mixins: [HomeMixin],
  data() {
    return {
      active: 0,
      description: ['大象是地球上珍贵的野生动物资源，非洲象，'
        + '亚洲象均被替换为《濒临灭绝的野生动植物种国际贸易公约》（CITES ）目录，'
        + '象牙及其制品的市场贸易持续得到国家决策层和学术界的从抵抗制象牙及制品做起，'
        + '对保护野生动物资源具有重要意义，而在执法过程中如何快速，有效的对类象牙制品进行鉴定与识别，'
        + '是目前亟待解决的问题。 ',
      '本项目采用图像增强与纹理解析方法，来辅助解决象牙图像纹理的量化解析问题，'
        + '通过深度学习算法实现象牙图像鉴定和识别'],
      resultData: [{noUpload: true}]
    }
  },
  computed: {
  },
  methods: {
    changeClass(index) {
      this.active = index
    },
    getSlideIndex(e) {
      this.active = e
    },
    getResult(e) {
      this.resultData = e
    }
  },
  components: {
    PcHeader,
    PcFooter,
    ImgUpload,
    ImgSwiper
    // ImgCarousel
  },
  created() {},
  mounted () {
    this.getExamplesList()
  }
}
</script>

<style lang="less" scoped>
  .main{
    width: 100%;
    >img {
      width: 100%;
    }
    .content {
      width: 1200px;
      margin: 0 auto;
      .sample{
        width: 100%;
        min-height: 350px;
        padding-top: 100px;
        .title {
          font-size: 22px;
          font-weight: 600;
          color: #333333;
          line-height: 30px;
          margin: 0;
        }
        >hr {
          width: 50px;
          height: 3px;
          border: none;
          background: #333333;
        }
        .menu {
          margin-top: 30px;
          margin-bottom: 50px;
          >div {
            display: inline-block;
            font-size: 22px;
            font-weight: 400;
            color: #333333;
            line-height: 48px;
            &.active {
              background-color: #ddbf83;
            }
            &:hover{
              cursor: pointer;
            }
          }
          >div:nth-of-type(1) {
            width: 160px;
            height: 48px;
            border: 2px solid #ddbf83;
            border-radius: 31px 4px 4px 31px;
          }
          >div {
            width: 160px;
            height: 48px;
            border: 2px solid #ddbf83;
            border-radius: 4px;
            margin-left: 20px;
            margin-right: 20px;
          }
          >div:nth-last-of-type(1) {
            width: 160px;
            height: 48px;
            border: 2px solid #ddbf83;
            border-radius: 4px 31px 31px 4px;
          }
        }
        .display {
          /*/deep/ .slick-dots.slick-dots-bottom.slick-dots.slick-thumb{*/
          /*  display: none !important;*/
          /*}*/
          /deep/ .slick-arrow {
            color: red;
            width: 25px;
            height: 25px;
            font-size: 25px;
            background-color: rgba(31, 45, 61, 0.11);
            opacity: 0.3;
          }
        }
      }
      .operation {
        width: 1200px;
        height: 380px;
        padding: 50px;
        background: #6c6f5c;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        .img-upload {
          width: 350px;
          margin-right: 100px;
          >p {
            font-size: 22px;
            font-weight: 600;
            text-align: left;
            color: #ffffff;
            line-height: 30px;
          }
          .upload-area  {
            height: 220px;
            /deep/ .ant-upload.ant-upload-drag{
              background-color: rgba(108,111,92,1);
              border: 2px dashed #d9d9d9;
              width: 350px;
              height: 220px;
              border-radius: 10px;
              .anticon.anticon-plus{
                color: #ffffff;
              }
              .ant-upload-text {
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
                margin-top: 20px;
              }
              .ant-upload-hint {
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                line-height: 22px;
                margin-top: 30px;
              }
            }
          }
          /deep/ .ant-upload.ant-upload-select.ant-upload-select-picture-card {
            background-color: rgba(108, 111, 92, 1);
            border: 2px dashed #d9d9d9;
            width: 350px;
            height: 220px;
            border-radius: 10px;
            margin: 0!important;
            .anticon.anticon-plus {
              font-size: 40px;
              font-weight: 600;
              color: #ffffff;
            }

            .ant-upload-text {
              > p:first-of-type {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                margin-top: 20px;
              }
              > p:nth-of-type(2) {
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                margin-top:30px;
              }
            }
          }
          /deep/ .ant-upload-list.ant-upload-list-picture-card{
            /*background-color: rgba(108, 111, 92, 1);*/
            /*border: 2px dashed #d9d9d9;*/
            /*width: 350px;*/
            /*height: 220px;*/
            /*border-radius: 10px;*/
            .ant-upload-list-picture-card-container{
              width: 350px;
              height: 220px;
            }
            .ant-upload-list-item{
              width: 350px;
              height: 220px;
              img{
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .texture-result{
          width: 330px;
          float: left;
          margin-right: 50px;
          >p {
            font-size: 22px;
            font-weight: 600;
            text-align: left;
            color: #ffffff;
            line-height: 30px;
          }
          >div {
            width: 100px;
            min-height: 100px;
            background: #ddbf83;
            border-radius: 8px;
            display: inline-block;
            margin-bottom: 20px;
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(4),
            &:nth-of-type(5){
              margin-right: 15px;
            }
          }
        }
        .recog-result {
          width: 350px;
          >p {
            font-size: 22px;
            font-weight: 600;
            text-align: left;
            color: #ffffff;
            line-height: 30px;
          }
          >div {
            width: 350px;
            min-height: 220px;
            border: 2px dashed #d9d9d9;
            border-radius: 10px;
            span{
              display: block;
              font-size: 18px;
              font-weight: 400;
              text-align: left;
              color: #ffffff;
              line-height: 25px;
              padding-left: 20px;
              margin-top: 10px;
              &:nth-of-type(1){
                margin-top: 20px;
              }
            }
          }
        }
      }
      .description {
        width: 1200px;
        margin-top: 100px;
        margin-bottom: 100px;
        display: block;
        >div {
          width: 550px;
          min-height: 350px;
          margin-right: 30px;
          display: inline-block;
          >p {
            font-size: 22px;
            font-weight: 600;
            text-align: left;
            color: #333333;
            line-height: 30px;
          }
          >hr {
            width: 50px;
            height: 3px;
            margin: 0 0 30px 0;
            background: #333333;
          }
          >div {
            width: 550px;
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            display: block;
            line-height: 32px;
            text-align: left;
            text-indent: 35px;
          }
        }
        >img{
          width: 620px;
          height: 350px;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
</style>
